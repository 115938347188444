<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide() {
    return {
      reload1: this.reload1
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload1() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
  * {
    font-size: 12px!important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
</style>

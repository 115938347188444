import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('./components/Login.vue')
const Home = () => import('./components/Home.vue')
const Welcome = () => import('./components/Welcome.vue')
const Register = () => import('./components/Register.vue')


// 常规管理
const Personal = () => import(/* webpackChunkName: "System" */ './components/System/Personal.vue')
const Annex = () => import(/* webpackChunkName: "System" */ './components/System/Annex.vue')
const System = () => import(/* webpackChunkName: "System" */ './components/System/System.vue')
const Payinfo = () => import(/* webpackChunkName: "System" */ './components/System/Payinfo.vue')

// 权限管理
// const Processing = () => import('./components/Produce/Processing.vue')
const AdminList = () => import(/* webpackChunkName: "Jurisdiction" */ './components/Jurisdiction/AdminList.vue')
const Role = () => import(/* webpackChunkName: "Jurisdiction" */ './components/Jurisdiction/Role.vue')
const JurisdictionList = () => import(/* webpackChunkName: "Jurisdiction" */ './components/Jurisdiction/JurisdictionList.vue')
const Log = () => import(/* webpackChunkName: "Jurisdiction" */ './components/Jurisdiction/Log.vue')


// 名师管理
const Teacher = () => import(/* webpackChunkName: "Teacher" */ './components/Teacher/Teacher.vue')
// 学校管理
const School = () => import(/* webpackChunkName: "School" */ './components/School/School.vue')
// 资源管理
// 资源列表
const Resource = () => import(/* webpackChunkName: "Resource" */ './components/Resource/Resource.vue')
// 资源分类
const ResourceCate = () => import(/* webpackChunkName: "Resource" */ './components/Resource/ResourceCate.vue')
const Restriction = () => import(/* webpackChunkName: "Resource" */ './components/Resource/Restriction.vue')
// 预约管理
const Reservation = () => import(/* webpackChunkName: "Reservation" */ './components/Reservation/Reservation.vue')
const ReservationTime = () => import(/* webpackChunkName: "Reservation" */ './components/Reservation/ReservationTime.vue')

// 数据统计
// 学校统计
const Statistics_school = () => import(/* webpackChunkName: "Statistics" */ './components/Statistics/Statistics_school.vue')
// 老师统计
const Statistics_teacher = () => import(/* webpackChunkName: "Statistics" */ './components/Statistics/Statistics_teacher.vue')



Vue.use(VueRouter)



const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/register', component: Register },

  {
    path: '/home', component: Home, redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      // 个人资料
      { path: '/personal', component: Personal },
      // 平台银行信息
      { path: '/payinfo', component: Payinfo},
      // 系统配置
      { path: '/system', component: System },
      // 附件管理
      { path: '/annex', component: Annex },
      // 管理员列表
      { path: '/adminList', component: AdminList },
      // 管理员日志
      { path: '/log', component: Log },
      // 角色列表
      { path: '/role', component: Role },
      // 权限列表
      { path: '/jurisdictionList', component: JurisdictionList },
     
      // 名师管理
      { path: '/teacher', component: Teacher },
      // 学校管理
      { path: '/school', component: School},
      // 资源管理
      // 资源分类
      { path: '/resourceCate', component: ResourceCate},
      // 资源列表
      { path: '/resource', component: Resource},
      // 限制资源
      { path: '/restriction', component: Restriction},
      // 预约管理
      { path: '/reservation', component: Reservation},
      // 预约时间管理
      { path: '/reservationTime', component: ReservationTime},
      // 学校统计
      { path: '/statistics_school', component: Statistics_school},
      // 老师统计
      { path: '/statistics_teacher', component: Statistics_teacher},

     

    ]
  }

]

const router = new VueRouter({
  // mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   next()
// })

export default router

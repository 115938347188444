import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// import Element from 'element-ui'


// 导入字体图标
import './assets/font/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'

// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 导入富文本编辑器对应点样式
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor)
import axios from 'axios'

let url = link + '/'
let imgUrl = link

axios.defaults.baseURL = url
// axios.defaults.baseURL = '/api/'
//添加请求拦截器
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  // config.headers.token = window.localStorage.getItem('token')
  
  return config
})
//添加响应拦截器


axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log(222,response)
  if(response.data.code === 100001) {
    // Element.Message.error(response.data.msg)
    router.push('/')
    return 
  }
  return response
});
// Vue.prototype.$echarts = echarts
// // 上传文件/图片
Vue.prototype.$URL = url

// 显示图片

Vue.prototype.$URL_img = imgUrl
Vue.prototype.$http = axios

Vue.filter('dateFormat', function(originVal) {
  const dt = new Date(originVal * 1000)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
